import React from "react"
import tw, { styled, css } from "twin.macro"
import AppIcon from "@atoms/icon"

const StoryNameWrapper = styled.div(({ isActive }) => [
  tw`text-center absolute bottom-0 left-0 pb-8 xl:pb-16`,
  css`
    @media (min-aspect-ratio: 7/3), (max-height: 400px) {
      ${tw`pb-2`}
    }
    @media (min-aspect-ratio: 7/3) and (min-width: 1280px) {
      ${tw`pb-6`}
    }
  `,
  !isActive &&
    css`
      opacity: 0.5;
      @media (max-width: 660px) {
        ${tw`pb-0 text-left -mb-2`}
        transform: rotate(270deg);
        transform-origin: top left;
      }
    `,
])

const headingStyle = tw`
  uppercase relative text-white font-display font-black text-2xl leading-none
  md:(text-3xl leading-none)
  xxl:(text-4xl leading-none)
`

const StoryName = ({ story, isActive, closedWidth }) => {
  return (
    <StoryNameWrapper
      isActive={isActive}
      style={{ width: isActive ? "100%" : `${closedWidth}vw` }}
    >
      <p
        tw="m-0 text-brand-green text-sm whitespace-nowrap md:text-base"
        initial={{ y: -20, opacity: 0 }}
        exit={{ x: 200, opacity: 0 }}
        animate={{ y: 0, x: 0, opacity: 1 }}
      >
        {story.location}{" "}
        {story.story_preview?.subtitle && (
          <span tw="text-white text-sm">/ {story.story_preview.subtitle}</span>
        )}
      </p>
      <h3 css={headingStyle}>
        <span>{story.character_name}</span>
        {isActive && story.released && (
          <span
            tw="absolute bottom-0 p-1 ml-2 inline-block bg-brand-blue text-white border-4 rounded-full"
            css={`
              @media (min-width: 1440px) {
                margin-bottom: 0.3rem;
              }
            `}
          >
            <AppIcon name="arrow_right" tw="w-3 h-3 block" />
          </span>
        )}
      </h3>
    </StoryNameWrapper>
  )
}

export default StoryName
