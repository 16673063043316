import React from "react"
import tw, { css } from "twin.macro"
import { motion } from "framer-motion"
const HeroLoop = ({ caption, isShallow }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    css={[
      tw`absolute`,
      css`
        height: 110%;
        top: 5%;
        left: -5%;

        @media (min-width: 375px) {
          height: 120%;
          top: -10%;
          left: -10%;
        }
      `,
    ]}
  >
    <defs>
      <path id="loop_path" d="M5,50A45,45 0 1 195,50A45,45 0 1 15,50"></path>
    </defs>
    <motion.path
      d="M6,50A44,44 0 1 194,50A44,44 0 1 16,50"
      style={{
        stroke: "white",
        strokeOpacity: 0.57,
        fill: "black",
        strokeWidth: 0.5,
      }}
      variants={{
        out: {
          pathLength: 0,
          pathOffset: 1,
          fillOpacity: 0,
          transition: { duration: 0.4 },
        },
        in: {
          pathLength: 1,
          pathOffset: 0,
          fillOpacity: 0.27,
          transition: { duration: 2 },
        },
      }}
      transition={{
        duration: 2,
      }}
    />
    <motion.text
      style={{ fontSize: "20%", fill: "white" }}
      tw="uppercase text-white"
      textAnchor="middle"
      variants={{
        out: {
          letterSpacing: 1000,
          opacity: 0,
          transition: { duration: 0.4 },
        },
        in: { letterSpacing: 0.5, opacity: 1, duration: 2 },
      }}
    >
      <textPath xlinkHref="#loop_path" startOffset={isShallow ? "10%" : "14%"}>
        {caption}
      </textPath>
    </motion.text>
  </svg>
)

export default HeroLoop
