import tw, { css } from "twin.macro"
import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import BackgroundVideo from "../../atoms/background-video"
import StoryName from "./name"

const StoryGatewaySlide = ({ story, isActive, closedWidth, children }) => {
  if (!story.story_preview) return ""
  const image = getImage(story.story_preview.poster?.localFile)
  const hasVideo = story.story_preview.vimeo_data || story.story_preview.trailer
  return (
    <motion.div
      tw="absolute inset-0"
      initial="out"
      animate={isActive && hasVideo ? "in" : isActive ? "novideo" : "out"}
    >
      {hasVideo && (
        <motion.div
          className="half-tone"
          key={`story_video_${story.id}`}
          css={[tw`absolute inset-0`, !isActive && tw`hidden`]}
        >
          <BackgroundVideo
            localVideo={story.story_preview.trailer}
            vimeoVideo={story.story_preview.vimeo_data}
            fallbackImage={story.story_preview.poster}
            width={720}
            height={720}
          />
        </motion.div>
      )}
      <motion.div
        className="half-tone"
        key={`story_image_${story.id}`}
        tw="overflow-hidden absolute left-0 top-0 bottom-0"
        css={
          isActive
            ? css`
                width: 100%;
              `
            : css`
                width: ${closedWidth}vw;
              `
        }
        variants={{
          in: { filter: `blur(20px)`, opacity: 0 },
          out: { filter: `blur(0)`, opacity: 0.4 },
          novideo: { filter: `blur(0)`, opacity: 1 },
        }}
        transition={{ ease: "easeInOut" }}
      >
        <GatsbyImage
          tw="inset-0 object-cover w-full h-full !absolute"
          image={image}
          alt={story.story_preview.poster?.alternativeText}
          aria-hidden="true"
        />
      </motion.div>

      {children && (
        <div tw="absolute inset-0 flex items-center justify-center pointer-events-none pb-20">
          {children}
        </div>
      )}

      <div
        tw="absolute w-full bottom-0 left-0 pb-20 px-6"
        className="gradient-bottom-before"
      >
        <StoryName
          story={story}
          isActive={isActive}
          closedWidth={closedWidth}
        />
      </div>
    </motion.div>
  )
}

export default StoryGatewaySlide
