import * as React from "react"
import { graphql } from "gatsby"
import Seo from "@atoms/seo"
import StoryGateway from "../components/organisms/story-gateway"
import DynamicZone from "../components/organisms/dynamic-zone"

const HomePage = ({
  data: {
    strapiHome: { seo, stories, intro_loop, containers },
  },
}) => {
  const _stories = stories.sort((a, b) => a?.order - b?.order)
  return (
    <>
      {seo && <Seo {...seo} />}
      <StoryGateway stories={_stories} intro={intro_loop} />
      {containers && containers.length > 0 ? (
        <DynamicZone components={containers} />
      ) : (
        ""
      )}
    </>
  )
}

export const query = graphql`
  query Home($locale: String) {
    strapiHome(locale: { eq: $locale }) {
      seo {
        ...SeoFragment
      }
      intro_loop {
        ...IntroAnimation
      }
      stories {
        ...StoryGateway
      }
      containers {
        ...DynamicZoneFragment
      }
    }
  }
`

export default HomePage
