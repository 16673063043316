import { graphql } from "gatsby"
import React from "react"
import loadable from "@loadable/component"
const Hero = loadable(() => import("../molecules/hero"))
const BlockQuote = loadable(() => import("../molecules/block-quote"))
const Waterfall = loadable(() => import("./waterfall/waterfall"))
const PhotoGalleryCover = loadable(() =>
  import("./photo-gallery/photo-gallery-cover")
)
const TextContent = loadable(() => import("../molecules/text-content"))
const ColumnsSection = loadable(() => import("./columns_section"))
const FeatureText = loadable(() => import("../molecules/feature-text"))
const HeroBanner = loadable(() => import("../molecules/hero-banner"))

const DynamicZone = ({ components, context }) => {
  return components.map(container => {
    const key = `${container.strapi_component}_${container.id}`
    switch (container.strapi_component) {
      case `molecules.banner`:
        return <HeroBanner key={key} data={container} />
      case `molecules.hero`:
        return <Hero key={key} data={container} />
      case `molecules.blockquote`:
        return <BlockQuote key={key} data={container} />
      case `organisms.waterfall`:
        return <Waterfall key={key} data={container} context={context} />
      case `organisms.photo-gallery`:
        return <PhotoGalleryCover key={key} data={container} />
      case `molecules.text-content`:
        return <TextContent key={key} data={container} />
      case `organisms.columns-section`:
        return <ColumnsSection key={key} data={container} />
      case `molecules.feature-text`:
        return <FeatureText key={key} data={container} />
      default:
        return null
    }
  })
}

export const query = graphql`
  fragment DynamicZoneFragment on DynamicZoneContainer {
    strapi_component
    ...HeroFragment
    ...HeroBannerFragment
    ...BlockQuoteFragment
    ...WaterfallFragment
    ...PhotoGalleryFragment
    ...TextContentFragment
    ...ColumnsSectionFragment
    ...FeatureTextFragment
  }
`

export default DynamicZone
