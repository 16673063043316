import { styled } from "twin.macro"

const SlideWrapper = styled.div`
  padding-bottom: ${(16 / 9) * 100}%;
  width: 100%;
  position: relative;
  @media (min-aspect-ratio: 2/3) {
    padding-bottom: ${(4 / 3) * 100}%;
  }
  @media (min-aspect-ratio: 4/3) {
    padding-bottom: 100%;
  }
  @media (min-aspect-ratio: 7/5) {
    padding-bottom: ${(3 / 4) * 100}%;
  }
`
export default SlideWrapper
